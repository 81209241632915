
.slideFromRight{
    -webkit-animation-name:slideInRight;
            animation-name:slideInRight;
    -webkit-animation-duration: 0.25s;
            animation-duration: 0.25s;
}
.slideFromLeft{
    -webkit-animation-name:slideInLeft;
            animation-name:slideInLeft;
    -webkit-animation-duration: 0.25s;
            animation-duration: 0.25s;
}

[v-cloak] {
  display: none;
}
.showDropdown {
  display:block!important;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.slide-fade-enter-active {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.slide-fade-leave-active {
  -webkit-transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  opacity: 0;
}

body {
  background-color: #ededed;
}
#searchFilter {
}
#searchFilter h3{
  font-weight: 200;
  color: rgb(96,45,108);
  padding: 5px 10px;
}
#searchFilter a {
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 30px;
}
#searchFilter h3 i {
  float: right;
}

.filter-box {
  background-color: #ffffff;
  padding: 5px 15px;
  margin-bottom: 2px;
}
.filter-items {
  max-height: 300px;
  overflow: scroll
}
.filter-items label {
  width: 100%;
}
.filter-loading {
  position: absolute;
  top:0;
  left: 15px;
  right: 15px;
  bottom: 0;
  background-color: rgba(0,0,0,0.2);
}
.filter-checkbox label {
  font-weight: 300;
}
.filter-checkbox{
  padding:5px 0;
  margin-bottom: 0;
}
.filter-checkbox:hover {
  background-color: #f6f6f6;
}
.filter-item-count {
  float: right;
}

#searchclear {
    position: absolute;
    right: 7px;
    top: 0;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #ccc;
}
.brand-filter-brn-group {
  width: 100%;
}

.filter-box i{
  float: right;
}
h4 {
  cursor: pointer;
  color: #5f306a;
  font-size:1.4em;
  font-variant: all-small-caps;
}
.selected {
  font-weight: 400!important;
}
.filter-categories a, .filter-categories a:hover {
  color: rgb(51, 51, 51);
  font-weight: 200;
}
#search-bc{
  font-weight: 200;
  margin-bottom:15px;
  font-size: 0.9em;
}
.search-bc-item {
  text-overflow: ellipsis;
}
.search-item-count{
  float:right;
  color: #b2b3b7;
}


body {
    font-family: "Open Sans";
}
#search-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
#search-items:before, #search-itemsafter{
   display: none;
}
#search-items:after {
    content: "";
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
}
.search-item {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.item-holder {
    margin-bottom: 15px;
    padding: 15px 5px;
    border-radius: 4px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.item-image-holder{
    height: 260px;
}
.item-image{
    width: 100%;
    height: 100%;
    display: block;
    vertical-align: middle;
    text-align: center;
    line-height: 220px;
}
.item-image img{
    position: relative;
    max-height: 100%;
    max-width: 100%;
}
.item-info {
    width:100%;
    display:block;
}
.item-info h3 {
    color: rgb(96, 45, 108);
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    margin:10px 10px 0px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
    height: 16px;
}
.item-price{
    text-align: center;
}
.item-price-old {
    text-decoration: line-through;
}
.item-price-sale{
    color: #d70954;
}
.item-shop {
    white-space: nowrap;
    font-size: 0.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
.item-info a {
    color: rgb(96, 45, 108);
    text-decoration: none;
}
  /* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
}
  /* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
}
  /* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}


.price-input-holder{
  margin:5px 8px;
}
.price-input {
  width: 45%;
  text-align: center;
  font-size: 0.9em;
  padding:3px;
}
.price-input-max{
  float:right;
}

#search-header-holder{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height:30px;
  line-height:30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#search-header{
  font-size: 24px;
  font-weight: 200;
  text-align: center;
}
#search-subheader{
  text-align: center;
  font-weight: 200;
  margin-top: 10px;
  margin-bottom: 0px;
}
#search-subheader a {
  font-weight: 400;
    -webkit-text-decoration:dotted;
            text-decoration:dotted;
    color: rgb(96,45,108);
}
.rotate {
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
#order-select{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  cursor: pointer;
  font-weight: 400;
  background-color: transparent;
  outline:none;
  text-align: right;
}
#order-dropdown-holder{
  position: relative;
}
#order-dropdown-holder .dropdown-menu {
  display: block;
}
#order-dropdown {
    padding: 10px 15px 10px 20px;
    left: 50%;
    right: auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    min-width:220px;
    font-size:0.9em;
}
#search-order .fa {
  position: absolute;
  top: 10px;
  right: 16px;
}
#filter-tags{
  text-align: center;
  margin-bottom:15px;
}
.filter-badge {
    color: rgb(96, 45, 108);
    background-color: transparent;
    border: 1px solid rgb(96, 45, 108);
    line-height: 18px;
    margin: 0 5px 5px 0;
    text-align: center;
}
.fa-times-thin:before {
  content: '\D7';
}
.remove-tag{
  cursor: pointer;
}
.rotate{
    -webkit-transition: all 0.25s linear;
    transition: all 0.25s linear;
}
.rotate-down{
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.toggle-filters{
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.slide-fade-enter-active {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.slide-fade-leave-active {
  -webkit-transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  opacity: 0;
}
#bestOffer h3 {
  text-align:center;
}
.pulse-btn {
  font-size: 20px;
  padding: 0;
  line-height: 36px;
  border-radius: 4px;
  text-align: center;
  background-color: rgba(96, 45, 108, 1);
  cursor: pointer;
  color: rgb(254, 254, 254);
  border: none;
  -webkit-box-shadow: 0 0 0 0 rgba(96, 45, 108, 1);
          box-shadow: 0 0 0 0 rgba(96, 45, 108, 1);
  -webkit-animation: pulse 1.8s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.8s infinite cubic-bezier(0.66, 0, 0, 1);
  margin:25px auto 0 auto;
  width: 100%;
  padding:5px 10px;
  display: block;
  width: 80%;
}
.pulse-btn:hover
{
  color: rgb(254, 254, 254)!important;
  -webkit-animation: none;animation: none;
  background-color: rgba(96, 45, 108, 0.8);
}
@-webkit-keyframes pulse {
to {-webkit-box-shadow: 0 0 0 15px rgba(96, 45, 108, 0);box-shadow: 0 0 0 15px rgba(96, 45, 108, 0);
}
}
@keyframes pulse {
to {-webkit-box-shadow: 0 0 0 15px rgba(96, 45, 108, 0);box-shadow: 0 0 0 15px rgba(96, 45, 108, 0);
}
}
